::-webkit-scrollbar-track {
	background-color: #e9edf0;
	border-left: 1px solid #ededed;
}

::-webkit-scrollbar {
	width: 7px;
	background-color: #222;
}

::-webkit-scrollbar-thumb {
	background: #222;
}

#sidebar::-webkit-scrollbar-track {
	background-color: #e9edf0;
	border-left: 1px solid #ededed;
}

#sidebar::-webkit-scrollbar {
	width: 7px;
	background-color: #222;
}

#sidebar::-webkit-scrollbar-thumb {
	background: #222;
}

html {
	scroll-behavior: smooth;
	scroll-padding: 100px 0px 0px 100px;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	-webkit-text-size-adjust: 100%;
}

body {
	font-family: "Open Sans", sans-serif;
	font-size: 16px !important;
	color: #888;
	font-weight: 400;
	background: #f4f7fa;
	position: relative;
}

*:focus {
	outline: none;
}

a:hover {
	outline: none;
	text-decoration: none;
}

.btn {
	border-radius: 2rem !important;
}
nav.navbar {
	z-index: 99;
}

.content-wrapper {
	flex-grow: 1;
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
}

.match-height > [class*="col"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column;
	-ms-flex-flow: column;
	flex-flow: column;
}

.match-height > [class*="col"] > .card {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.tabel-div {
	display: flex;
	text-align: center;
	align-items: center;
}
.tabel-div > div {
	display: flex;
	align-items: center;
	text-align: center;
	border: 1px solid rgb(0 0 0 / 15%);
	padding: 5px;
	height: 50px;
	margin-top: -1px;
	margin-right: -1px;
	font-size: 85%;
	color: rgb(0 0 0 / 75%);
}
.tabel-div > div.extra-narrow {
	width: 5%;
}
.tabel-div > div.narrow {
	width: 10%;
}
.tabel-div > div.wide {
	width: 22%;
}

a,
a:hover,
a:focus {
	text-decoration: none !important;
}
/* .tabel-hover {
    cursor: pointer;
    transition: all 150ms;
    background: transparent;
}
.tabel-hover:hover {
    cursor: pointer;
    transition: all 150ms;
    background: red;
} */
a#toTop {
	position: fixed;
	bottom: 30px;
	right: 40px;
	background: #fff;
	color: #555;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	font-size: 15px;
	border-radius: 100%;
	font-weight: bolder;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
	z-index: 9;
	transition: all 150ms;
	transform: scale(0.9);
}

a#toTop:hover {
	transition: all 150ms;
	transform: scale(1);
}

.b-shadow {
	box-shadow: 5px 5px 15px rgb(0 0 0 / 0.1);
	border: 1px solid rgb(0 0 0 / 0.1);
	transition: all 150ms;
}

.b-radius {
	border-radius: 10px !important;
}

a.card.b-shadow:hover {
	box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
	transition: all 150ms;
}

.card.hover:hover {
	transform: translateY(-6px);
	box-shadow: 0px 0px 1px rgb(0 0 0 / 30%);
}

.card.hover .icon-holder {
	width: 80px !important;
	height: 80px !important;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border-radius: 100%;
}

.card.hover:hover .icon-holder {
	box-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
}

.card.hover .icon-holder i {
	transform: scale(1);
	transition: all 150ms;
}

.card.hover:hover .icon-holder i {
	transform: scale(1.5);
	transition: all 150ms;
}

.card.hover span {
	font-weight: normal;
	transform: scale(1);
	transition: all 250ms;
}

.card.hover:hover span {
	font-weight: bold;
	transform: scale(1.2);
	transition: all 250ms;
}

.text-cut {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}

.image {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	aspect-ratio: 1/1;
}

.background {
	background-attachment: fixed;
	background-position: left;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 94vh;
}

.upload-preview {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 120px;
	height: 90px;
}

.login {
	height: 94vh;
}

.line-inside {
	border-bottom: 1px solid #dadada;
	line-height: 0.1em;
	margin: 30px 0;
}

.line-inside span {
	background: #fff;
	padding: 0 10px;
}

li.nav-item button {
	background: rgb(0 0 0 / 10%);
}

li.nav-item button.active {
	background: #2f485c;
	color: #fff;
	box-shadow: 15px 15px 35px rgb(0 0 0 / 0.3);
	transition: all 150ms;
}

li.dropdown-menu {
	display: block;
	min-width: 160px;
	border: 1.5px solid #e6e6e6;
	opacity: 0.3;
	transform-origin: top;
	animation-fill-mode: forwards;
	transform: scale(1, 0);
	transition: all 0.2s linear;
	box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
}

li.dropdown.show ul.dropdown-menu .show {
	transform: scale(1, 1);
	opacity: 1;
}

#sidebar {
	display: flex;
	width: 360px;
	z-index: 9;
	border: 0;
	position: sticky;
	height: 100vh;
	float: left;
	top: 0;
	left: 0;
	transition: all 150ms;
	flex-direction: column;
	background-color: #fff;
}

#sidebar.hide {
	width: 100px;
	display: flex;
	z-index: 9;
	transition: all 150ms;
}

.menu-wrapper {
	overflow-y: scroll !important;
	height: 75vh;
}

#sidebar .logo-wrapper {
	z-index: 1;
	position: sticky;
	top: 0;
}

#sidebar img.logo {
	max-width: 220px;
	margin: 5% 0 10% 0;
	transition: all 250ms;
}

#sidebar.hide img.logo {
	max-width: 75px;
	margin: 5% 0 10% 0;
	padding: 5px;
	transition: all 250ms;
}
#sidebar .sidebar_link {
	color: inherit;
	margin: 5px 0;
	padding: 10px 15px;
	border-radius: 8px;
	transition: all 250ms;
}
#sidebar .sidebar_link:hover {
	color: inherit;
	margin: 5px 0;
	padding: 10px 15px;
	border-radius: 8px;
	transition: all 250ms;
	background: rgb(137 137 137 / 10%);
}
#sidebar.hide .sidebar_link.active {
	color: #6466f1;
	margin: 5px 0;
	padding: 10px 15px;
	border-radius: 8px;
	transition: all 250ms;
	background: rgb(100 102 241 / 10%);
}
#sidebar .sidebar_link.active {
	color: #6466f1;
	margin: 5px 0;
	padding: 10px 0px 10px 30px;
	border-radius: 8px;
	transition: all 250ms;
	background: rgb(100 102 241 / 10%);
}
.taggler {
	font-size: 22px;
	width: 35px;
	height: 35px;
	padding: 5px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: #696cff;
	transition: all 150ms;
}

.open .taggler {
	text-align: center;
	transition: all 150ms;
	position: absolute;
	top: 40px;
	left: 245px;
}

.hide .taggler {
	text-align: center;
	transition: all 150ms;
	position: absolute;
	top: 90px;
	left: 23px;
}

#sidebar.hide .link {
	display: none;
	transition: all 150ms;
}

.hide .p-listbox .p-listbox-list .p-listbox-item {
	padding-left: 32px;
}

.theme-customizer {
	position: fixed;
	right: 0;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #555;
	color: #fff;
	width: 40px;
	height: 40px;
	line-height: 0;
	border-radius: 10px 0 0 10px;
	border: 0;
	font-size: 25px;
}

.footer-bg {
	flex-shrink: 0;
	position: sticky;
	bottom: 0;
}

.mobile-logo {
	display: none;
}

.nav-item.mobile {
	display: none;
}

.bs-placeholder {
	height: calc(3rem + 2px);
}

.filter-option-inner-inner {
	padding-top: 4px;
	padding-bottom: 0px;
	padding-left: 6px;
	font-size: 14px;
}

.p-datatable-header {
	background-color: transparent !important;
	border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
	background-color: transparent !important;
	border-bottom: 1px solid #dee2e6;
}

.p-datatable .p-sortable-column:focus {
	outline: none !important;
	box-shadow: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	outline: none !important;
	box-shadow: none !important;
}

.list-group-counted li {
	counter-increment: number;
}

.list-group-counted li::before {
	content: counter(number) ". ";
}

.chat-input-container {
	position: absolute;
	bottom: 0;
	background: transparent;
	width: 92%;
}

.flash_animation {
	animation: flash 1s linear infinite;
}

@keyframes flash {
	50% {
		opacity: 0;
	}
}

@media (max-width: 1199px) {
	#sidebar.open {
		position: fixed;
		top: 0;
		bottom: 0;
		height: 100vh;
		left: 0;
		width: 360px;
		z-index: 999;
		overflow-y: auto;
		background-color: #fff;
		transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
		transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
		transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
			-webkit-transform 0.3s ease-in-out;
	}
	#sidebar.hide {
		position: fixed;
		top: 0;
		bottom: 0;
		height: 100vh;
		left: 0;
		width: 0;
		z-index: 999;
		overflow-y: auto;
		background-color: #fff;
		transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
		transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
		transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
			-webkit-transform 0.3s ease-in-out;
	}
}

@media (max-width: 768px) {
	.mobile-logo {
		display: block;
	}

	img.logo {
		display: block;
		width: 65px;
		float: left;
	}
}

.progress {
	--bs-progress-height: 0.75rem;
	--bs-progress-font-size: 0.625rem;
	--bs-progress-bg: rgba(67, 89, 113, 0.1);
	--bs-progress-border-radius: 10rem;
	--bs-progress-box-shadow: inset 0 1px 2px rgba(67, 89, 113, 0.075);
	--bs-progress-bar-color: #fff;
	--bs-progress-bar-bg: #696cff;
	--bs-progress-bar-transition: width 0.6s ease;
	display: flex;
	height: var(--bs-progress-height);
	overflow: hidden;
	font-size: var(--bs-progress-font-size);
	background-color: var(--bs-progress-bg);
	border-radius: var(--bs-progress-border-radius);
	border-radius: 10px;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: var(--bs-progress-bar-color);
	text-align: center;
	white-space: nowrap;
	background-color: var(--bs-progress-bar-bg);
	transition: var(--bs-progress-bar-transition);
	border-radius: 10px;
}

.progress {
	overflow: initial;
}

.progress-bar.bg-secondary {
	box-shadow: 0 2px 4px 0 rgba(133, 146, 163, 0.4);
}

.progress-bar.bg-success {
	box-shadow: 0 2px 4px 0 rgba(113, 221, 55, 0.4);
}

.progress-bar.bg-info {
	box-shadow: 0 2px 4px 0 rgba(3, 195, 236, 0.4);
}

.progress-bar.bg-warning {
	box-shadow: 0 2px 4px 0 rgba(255, 171, 0, 0.4);
}

.progress-bar.bg-danger {
	box-shadow: 0 2px 4px 0 rgba(255, 62, 29, 0.4);
}

.progress-bar.bg-light {
	box-shadow: 0 2px 4px 0 rgba(252, 253, 253, 0.4);
}

.progress-bar.bg-dark {
	box-shadow: 0 2px 4px 0 rgba(35, 52, 70, 0.4);
}

.progress-bar.bg-gray {
	box-shadow: 0 2px 4px 0 rgba(67, 89, 113, 0.4);
}

.light-style .bs-stepper .bs-stepper-header .line {
	color: rgba(67, 89, 113, 0.4);
}

html:not([dir="rtl"]) .bs-stepper .bs-stepper-header .step:first-child .step-trigger {
	padding-left: 0;
}

.bs-stepper .bs-stepper-header .step .step-trigger {
	padding: 0 1rem;
	flex-wrap: nowrap;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.light-style .bs-stepper .step-trigger {
	color: #697a8d;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

.bs-stepper .step-trigger {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 20px;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5;
	color: #6c757d;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: none;
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step.active .bs-stepper-circle {
	background-color: #696cff;
	color: #fff;
	box-shadow: 0 0.1875rem 0.375rem 0 rgba(105, 108, 255, 0.4);
}

.bs-stepper .bs-stepper-header .step .bs-stepper-circle {
	height: 2.5rem;
	width: 2.5rem;
	font-weight: 500;
	font-size: 1.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.375rem;
}

.active .bs-stepper-circle {
	background-color: #007bff;
}

.bs-stepper-circle {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-line-pack: center;
	align-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 2em;
	height: 2em;
	padding: 0.5em 0;
	margin: 0.25rem;
	line-height: 1em;
	color: #fff;
	background-color: #6c757d;
	border-radius: 1em;
}

html:not([dir="rtl"]) .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
	margin-left: 0.35rem;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
	margin: 0;
	max-width: 224px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: start;
	display: inline-grid;
	font-weight: 600;
}

.bs-stepper .step.active .bs-stepper-label {
	color: #696cff !important;
}

.bs-stepper .line {
	flex: 0;
	min-width: auto;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	margin: 0;
}

.light-style .bs-stepper .bs-stepper-header {
	border-bottom: 1px solid #d9dee3;
}

.bs-stepper .bs-stepper-header {
	padding: 1.185rem 1.125rem;
}

.bs-stepper-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
	font-size: 0.9375rem;
	line-height: 1;
	font-weight: 600;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
	margin: 0;
	max-width: 224px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: start;
	display: inline-grid;
	font-weight: 600;
}

.bs-stepper .step.active .bs-stepper-label {
	color: #696cff !important;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
	margin-top: 0.2rem;
	font-size: 0.75rem;
	font-weight: normal;
}

.light-style .bs-stepper .bs-stepper-header .step .bs-stepper-subtitle {
	color: #a1acb8;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.light-style .bs-stepper .bs-stepper-header {
	border-bottom: 1px solid #d9dee3;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
	margin: 0;
	max-width: 224px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: start;
	display: inline-grid;
	font-weight: 600;
}

.custom-option.checked {
	border: 1px solid #696cff;
}

.custom-option {
	padding-left: 0;
	border: 1px solid #d9dee3;
	border-radius: 0.5rem;
}

.custom-option.custom-option-image {
	border-width: 2px !important;
}

.custom-option.custom-option-image:hover {
	border-width: 2px !important;
}

.custom-option.custom-option-image .custom-option-body img {
	border-radius: 0.375rem;
}

.custom-option .custom-option-content {
	cursor: pointer;
	width: 100%;
}

.custom-option-basic .custom-option-content {
	padding: 1em;
	padding-left: 2.7em;
}

.custom-option-basic .custom-option-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 0.25rem;
}

.custom-option-icon .custom-option-content {
	text-align: center;
	padding: 1em;
}

.custom-option-icon .custom-option-body {
	display: block;
	margin-bottom: 0.5rem;
}

.custom-option-icon .custom-option-body i {
	font-size: 2rem;
	margin-bottom: 0.25rem;
	display: block;
}

.custom-option-icon .custom-option-body svg {
	height: 38px;
	width: 38px;
	margin-bottom: 0.25rem;
}

.custom-option-icon .custom-option-body .custom-option-title {
	display: block;
	font-size: 0.9375rem;
	font-weight: 500;
	color: #566a7f;
}

.custom-option-icon .form-check-input {
	float: none !important;
	margin: 0 !important;
}

.custom-option-image {
	border-width: 2px;
}

.custom-option-image .custom-option-content {
	padding: 0;
}

.custom-option-image .custom-option-body img {
	height: 100%;
	width: 100%;
}

.custom-option-image.custom-option-image-radio .form-check-input {
	display: none;
}

.custom-option-image.custom-option-image-check {
	position: relative;
}

.custom-option-image.custom-option-image-check .form-check-input {
	position: absolute;
	top: 10px;
	right: 10px;
	margin: 0;
	border: 0;
	opacity: 0;
}

.custom-option-image.custom-option-image-check .form-check-input:checked {
	opacity: 1;
}

.custom-option-image.custom-option-image-check:hover .form-check-input {
	border: inherit;
	border-width: 1px;
	opacity: 1;
}

.avatar svg {
	width: 50px;
	height: 50px;
	border-radius: 10px;
	padding: 8px;
}

.avatar.p-overlay-badge .p-badge {
	transform: translate(40%, -30%) !important;
}

.uploader-wrapper {
	position: fixed;
	bottom: 17px;
	z-index: 1;
	right: 17px;
	background: #fefefe;
}

.close-button {
	position: absolute;
	top: -15px;
	background: #fff;
	left: -17px;
	border: none;
	padding: 10px;
	border-radius: 100%;
	cursor: pointer;
}

.blur {
	background-color: rgba(255, 255, 255, 0.5);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	transition: all 250ms;
}

[hidden] {
	display: none !important;
}
.loader,
.loader:before,
.loader:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	animation-fill-mode: both;
	animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
	color: #c1c1c1;
	font-size: 7px;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;
}
.loader:before,
.loader:after {
	content: "";
	position: absolute;
	top: 0;
}
.loader:before {
	left: -3.5em;
	animation-delay: -0.32s;
}
.loader:after {
	left: 3.5em;
}
.no-bg {
	background-color: transparent !important; /* No background */
}

.no-border {
	border: none !important; /* No border */
}

.no-hover:hover {
	background-color: transparent !important; /* No hover effect */
}

@keyframes bblFadInOut {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
.card.processes .position-absolute {
	display: none !important;
	opacity: 0;
	transition: all 250ms;
}
.card.processes:hover .position-absolute {
	display: flex !important;
	opacity: 1;
	transition: all 250ms;
}

input.p-autocomplete-input.p-inputtext.p-component,
.p-inputtext {
	width: 100%;
}
